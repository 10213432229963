/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  conversation: null,
  part: null,

  admin: or('conversation.assignee', 'app.currentAdmin'),
  body: computed(function () {
    return {
      admin_id: this.get('admin.id'),
      user_id: this.get('conversation.main_participant.id'),
      conversation_id: this.get('conversation.id'),
      conversation_part_id: this.get('part.id'),
    };
  }),
});
