/* import __COLOCATED_TEMPLATE__ from './conversation-parts-quick-action-item.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import BaseConversationPartsDropdownItem from 'embercom/components/integrations/base/conversation-parts-quick-action-item';
import SalesforceTaskCreation from 'embercom/components/notifications/salesforce-task-creation';

export default BaseConversationPartsDropdownItem.extend({
  intercomEventService: service(),
  notificationsService: service(),
  router: service(),
  classes: ['test__create-salesforce-task-quick-action-item'].join(' '),

  icon: 'salesforce',
  message: 'New Salesforce task',
  notificationDuration: 10000,
  customNotificationKey: 'salesforce-task-notification',

  _notifyProcessing() {
    this.notificationsService.notifyLoading('Sending...', -1, this.customNotificationKey);
  },

  _notifySuccess(tasks) {
    this._trackEvent(true);
    this.notificationsService.removeNotification(this.customNotificationKey);
    this.notificationsService.notifyConfirmationWithModelAndComponent(
      {
        tasks,
        taskUserName: this.get('part.creator.display_as'),
      },
      SalesforceTaskCreation,
      this.notificationDuration,
    );
  },

  _notifyFailure() {
    this._trackEvent(false);
    this.notificationsService.removeNotification(this.customNotificationKey);
    this.notificationsService.notifyErrorWithButton(
      'Could not create Salesforce Task activity',
      {
        label: 'Learn more',
        action: this.redirectToSalesforceSettings.bind(this),
      },
      this.notificationDuration,
    );
  },

  redirectToSalesforceSettings() {
    this.router.transitionTo('apps.app.appstore', {
      queryParams: { app_package_code: 'salesforce-by-intercom' },
    });
  },

  _trackEvent(success) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'created',
      object: 'salesforce_task',
      models: [this.conversation, this.get('conversation.main_participant')],
      salesforce_object_type: 'lead/contact',
      success,
    });
  },

  createSalesforceTask: task(function* () {
    this._notifyProcessing();
    yield ajax({
      url: `/ember/salesforce_integrations/tasks?app_id=${this.get('app.id')}`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(Object.assign({ app_id: this.get('app.id') }, this.body)),
    })
      .then((response) => {
        this._notifySuccess(response.tasks);
      })
      .catch(() => {
        this._notifyFailure();
      });
  }).drop(),

  actions: {
    createSalesforceTask() {
      this.createSalesforceTask.perform();
    },
  },
});
