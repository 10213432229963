/* import __COLOCATED_TEMPLATE__ from './pricing-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type IntershopService from 'embercom/services/intershop-service';

import { clone } from 'underscore';

import type Plan from 'embercom/models/plan';
import { type Breakdown, type Charge } from 'lib/factory-generator/types/price';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import {
  PricingTooltipItemType,
  type PricingTooltipBaseIncludes,
} from 'embercom/models/data/pricing/pricing-summary-types';
import { TierPriceFormat } from 'embercom/models/data/pricing/tier-types';
import { type Metric } from 'embercom/models/data/pricing/metric-types';

interface Args {
  plan: Plan;
  selectedMetricsUsage: Partial<Record<Metric, number>>;
}

export default class PricingTooltipComponent extends Component<Args> {
  readonly OrderSummaryLineItemType = PricingTooltipItemType;

  @service declare intl: IntlService;
  @service declare intershopService: IntershopService;
  @service declare appService: any;

  get basePrice(): PricingTooltipBaseIncludes | undefined {
    return this.planBaseAndIncluded().find((item) => item.type === PricingTooltipItemType.BaseFee);
  }

  get includedSeatUsage(): PricingTooltipBaseIncludes | undefined {
    return this.planBaseAndIncluded().find(
      (item) => item.type === PricingTooltipItemType.IncludedUsage,
    );
  }

  get planPricingMetrics(): Array<PricingMetric> {
    let planPricing = this.intershopService.newPriceBreakdown.find(
      (breakdown: Breakdown) => breakdown.plan_id === this.args.plan.idAsNumber,
    );

    let planPricingMetricTemp = clone(planPricing.charges.toArray());
    // If it's a plan that requires people reached
    if (this.intershopService.peopleReachedPlanId(planPricing.plan_id)) {
      // get people reached charges from the newPriceBreakdown stick charges into the planPricingMetricTemp
      let peopleReachedTier = this.intershopService.peopleReachedPlanId(planPricing.plan_id);
      let peopleReachedPlanPricing = this.intershopService.newPriceBreakdown.find(
        (breakdown: Breakdown) => breakdown.plan_id === peopleReachedTier,
      );
      if (peopleReachedPlanPricing) {
        planPricingMetricTemp.push(peopleReachedPlanPricing.charges.firstObject);
      }
    }
    return planPricingMetricTemp.map((charge: Charge) => new PricingMetric(charge));
  }

  planBaseAndIncluded(): Array<PricingTooltipBaseIncludes> {
    let summary = new Array<PricingTooltipBaseIncludes>();
    this.planPricingMetrics.forEach((pricingMetric) => {
      if (pricingMetric.isSeatMetric) {
        if (
          pricingMetric.tiers.length >= 2 &&
          pricingMetric.tiers[0].price_format === TierPriceFormat.FlatFee &&
          pricingMetric.tiers[1].price_format === TierPriceFormat.PerUnit
        ) {
          // sometimes we don't want to surface the fact that there is included usage and want to display minus it from the base fee and include it in actual usage
          let basePrice = pricingMetric.tiers[0].price;
          let includedUsage = pricingMetric.tiers[0].ending_unit;

          if (pricingMetric.potentialZeroBase) {
            basePrice -=
              pricingMetric.tiers[0].ending_unit *
              this.intershopService.centsToDollars(pricingMetric.perUnitPrice);
            includedUsage = 0;
          }

          summary.push({
            metric: pricingMetric.metric,
            type: PricingTooltipItemType.BaseFee,
            price: this.intershopService.formatPrice(basePrice),
          });

          if (includedUsage > 0) {
            summary.push({
              metric: pricingMetric.metric,
              type: PricingTooltipItemType.IncludedUsage,
              usage: includedUsage,
              price: this.intershopService.formatPrice(0),
            });
          }
        }
      }
    });
    return summary;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingSummary::Order::Tooltip::PricingTooltip': typeof PricingTooltipComponent;
    'intershop/pricing/pricing-summary/order/tooltip/pricing-tooltip': typeof PricingTooltipComponent;
  }
}
